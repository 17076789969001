import React, { Component, useState } from "react";
import Header from "../Common/Header";
// import "../../assets/sass/Voucher.scss";
import { get, logout, post } from "../../utills/API";
import cookie from "react-cookies";
import { REZOR_PAY_KEY, PAYMENT_REDIRECT_URL } from "../../utills/Constants";
import Loader from "../Loader";

import { ToastContainer, toast } from "react-toastify";
import Promocode from "./Promocode";
// import "../../assets/sass/newDesign/brand.scss";
import arrowIcon from "../../assets/img/arrow-icon.png";
import { Link } from "react-router-dom";
import Review from "./Dashboard/Review";
import SearchBrand from "./Dashboard/SearchBrand";
import HowToRedeem from "./Dashboard/HowToRedeem";
import BrandStep from "./BrandStep";
import { encrypt, decrypt, getUrlParams } from "../../utills/function";
import ReactGA from "react-ga";

// import "../assets/scss/components/_brand.scss";
import {
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
  Button,
  InputGroup,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";
import brandalert from "../../assets/images/gainon/alert-icon.svg";
import redeem1 from "../../assets/images/gainon/redeem-step-1.svg";
import redeem2 from "../../assets/images/gainon/redeem-step-2.svg";
import redeem3 from "../../assets/images/gainon/redeem-step-3.svg";
import redeem4 from "../../assets/images/gainon/redeem-step-4.svg";
import tooltipicon from "../../assets/images/gainon/tooltip-icon.svg";
import VoucherIcon from "../svg-coupon";
import Footer from "../Common/Footer";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Simple tooltip
  </Tooltip>
);

class Brand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandData: {},
      count: 0,
      coupons: [],
      brandId: null,
      userDetail: {},
      isLoading: false,
      isProcess: false,
      showPromocode: false,
      //promocodes: [],
      promocode: "",
      promoApplied: false,
      promoCodeId: "",
      promoDiscount: "",
      brandName: "",
      showPromo: false,
      amount: "",
      useDiscount: "full",
      discountAmount: "",
      isReview: false,
      reviewData: {},
      minAmount: null,
      maxAmount: null,
      ngpDiscount: false,
      walletBalance: false,
      ngpDiscountValue: "",
      walletBalanceValue: "",
      isCheckoutLoader: false,
      isPayLoad: false,
      wallet: "paytm",
      isLoggedIn: false,
      totalBalance: 0,
      is_checkOut_call: false,
      alert_for_minimum_amount: false,
      pay_by_goPointBalance: false,
      alert_for_minimum_amount: false,
      GoPointDiscount: "",
      goPointsBalance: "",
      alert_for_maximum_amount: false,
    };

    this.paymentHandler = this.paymentHandler.bind(this);
  }

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  userDetail = (detail) => {
    // console.log(detail);
    this.setState({
      userDetail: detail,
      ngpDiscountValue: parseInt(detail.ngp_gift_discount),
      walletBalanceValue: parseInt(detail.wallet),
    });
  };

  logout = async () => {
    await logout();
    let isLogin = this.isCurrentUser();
    this.setState({ isLoggedIn: isLogin });
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { brand_id } = this.props.match.params;
    ReactGA.initialize("AW-458145258");
    if (brand_id) {
      try {
        const { data } = await get(`ngp/brands/${brand_id}`);
        let decBrandsData = decrypt(data.data.brand);
        if (decBrandsData && decBrandsData.is_redeem) {
          this.props.history.push("/voucher");
        }
        // console.log("decBrandsData: ", decBrandsData)
        var brandData = decBrandsData;
        var brandName = brandData.name;
        var coupons = brandData ? brandData.coupons : [];
        var minAmount;
        var maxAmount;
        if (coupons.length > 0 && coupons.length >= 1) {
          let couponQuantityLimit = brandData
            ? brandData.coupon_quantity_limit
              ? parseInt(data.data.brand.coupon_quantity_limit)
              : null
            : null;
          minAmount = coupons[0].amount;
          if (coupons[0].is_slab === 0) {
            maxAmount = coupons[0].max_amount
          } else {
            maxAmount = coupons[coupons.length - 1].amount * couponQuantityLimit;
          }
        }
      } catch (e) {
        console.log("error", e);
      }
    }
    // try {
    //   const { data } = await get("ngp/promocode/list");
    //   var promocodes = data.data.promocode;
    // } catch (e) {
    //   console.log("error", e);
    // }

    // get checkout data from localstorage
    let checkoutPath = localStorage.getItem("checkoutPath")
      ? localStorage.getItem("checkoutPath")
      : null;
    let checkoutAmount;

    if (checkoutPath === `/brand/${brand_id}`) {
      let parseCheckoutData = localStorage.getItem("checkoutData")
        ? JSON.parse(localStorage.getItem("checkoutData"))
        : {};
      checkoutAmount = localStorage.getItem("checkoutData")
        ? parseCheckoutData.review
          ? parseCheckoutData.review.amount
          : null
        : "";
    }

    this.setState({
      brandData,
      brandId: brand_id,
      isLoading: false,
      //promocodes: promocodes,
      brandName,
      minAmount,
      maxAmount,
      amount: checkoutAmount,
    });
    window.scrollTo(0, 0);
  }

  handleChange = (e) => {
    const GopointswitchId = document.getElementById('custom-switch-Go-Points')
    const walletSwitchId = document.getElementById('custom-switch')
    if (this.isCurrentUser()) {
      GopointswitchId.checked = false
      walletSwitchId.checked = false
      this.handleGoPointBalance(e.target.checked)
      this.setState({
        ngpDiscount: false,
        walletBalance: false,
        balance: this.state.amount
      })
    }
    this.setState({ reviewData: {} })
    const { name, value } = e.target;
    const re = /[^0-9]/g
    const zerore = /^0/
    if (re.test(value) || value > 1000000 || zerore.test(value) || value.includes(".")) return;
    this.setState({ [name]: value });
    // this.checkout(e.target.value);
  };

  check_for_review_API = () => {
    const GopointswitchId = document.getElementById('custom-switch-Go-Points')
    const walletSwitchId = document.getElementById('custom-switch')
    if (this.isCurrentUser()) {
      GopointswitchId.checked = false
      walletSwitchId.checked = false
      this.handleGoPointBalance(GopointswitchId.checked)
      this.setState({
        ngpDiscount: false,
        walletBalance: false,
        balance: this.state.amount
      })
    }
    if (this.state.amount !== "" && this.state.amount !== undefined) {
      this.setState({ reviewData: {} })
      this.checkout(this.state.amount);
    }
  }

  handleCount = (type, id) => {
    let coupons = this.state.coupons;
    let couponIndex = coupons.findIndex((coupon) => coupon.id === id);
    let coupon = coupons[couponIndex];
    if (type === "inc") {
      coupon["total_amount"] =
        coupon["count"] < 5
          ? (coupon["count"] + 1) * coupon["amount"]
          : coupon["amount"] * coupon["count"];
      coupon["count"] = coupon["count"] < 5 ? coupon["count"] + 1 : 5;
    } else {
      coupon["total_amount"] =
        coupon["count"] > 0
          ? (coupon["count"] - 1) * coupon["amount"]
          : coupon["amount"] * coupon["count"];
      coupon["count"] = coupon["count"] > 0 ? coupon["count"] - 1 : 0;
    }
    this.setState({ coupons: coupons });
  };

  adapCode = (txnId, utmTrack) => {
    (new Image).src = "https://trk.superadappt.com/pixel?adid=6005644e6eada532337285de&goal_value=purchase&sub1=" + txnId + "&sub2=" + utmTrack + "&sub3=&sub4="
  }

  trackPurchase = (orderData) => {
    const ReactPixel = require("react-facebook-pixel");
    ReactPixel.default.init("1430251647147026");
    ReactPixel.default.init("1326840351009817");
    ReactPixel.default.track("Purchase", { order_id: orderData.id });
    const utm_term = getUrlParams('utm_term', window.location.search)
    this.adapCode(orderData.order_id, utm_term);
    this.fireGTagEvent(orderData.total_amount, orderData.order_id);
  };

  paymentHandler = async (order_id, total_amount, decryptedData) => {
    const self = this;
    const options = {
      key: REZOR_PAY_KEY,
      amount: parseFloat(total_amount) * 100,
      name: `Payments for Gainon`,
      description: `${this.state.brandData.name} Voucher Purchase ${order_id}`,
      order_id: order_id,
      payment_capture: 1,

      async handler(response) {
        self.setState({ isLoading: true })
        const paymentId = response.razorpay_payment_id;
        try {
          let orderPaymentData = encrypt({
            paymentId: paymentId,
            order_id: order_id,
          });
          const { data } = await post("razorpay/payment", {
            payment: orderPaymentData,
          });
          console.log(data)
          if (data.status == 200) {
            // console.log("decryptedData2", decryptedData);
            self.trackPurchase(decryptedData);
            self.props.history.push(`/orders/${order_id}`);
          } else {
            alert(
              "Something went wrong. If payment deducted from your account/card please contact to support team"
            );
            self.props.history.push(`/voucher`);
          }
        } catch (e) {
          console.log("error--->", e);
          alert(
            "Something went wrong. If payment deducted from your account/card please contact to support team"
          );
        }
      },

      prefill: {
        name: `${this.state.userDetail.first_name} ${this.state.userDetail.last_name}`,
        email: `${this.state.userDetail.email}`,
        contact: `${this.state.userDetail.country_code}${this.state.userDetail.mobile}`,
      },
      notes: {
        address: `${this.state.userDetail.address}`,
        order_id: order_id,
      },
      theme: {
        color: "#9D50BB",
      },
    };

    console.log("options", options)
    
    const rzp1 = new window.Razorpay(options);

    rzp1.open();
  };

  checkout = async (amount) => {
    const reviewData = {
      review: {
        amount: amount ? amount : this.state.amount,
        brand_id: Number(this.state.brandId),
        // is_gift_amount: this.state.ngpDiscount,
        // gift_amount: Number(this.state.ngpDiscountValue),
        is_wallet_amount: this.state.walletBalance,
        wallet_amount: Number(this.state.walletBalanceValue),
        promo_code_id: this.state.promoCodeId,
        user_id: this.state.userDetail.id,
        go_points: this.state.userDetail.go_points

      },
    };
    // console.log("min_amt: ", this.state.minAmount)
    // console.log("max_amt: ", this.state.maxAmount)
    if (this.state.brandId && !this.state.maxAmount) {
      if (amount >= this.state.minAmount && amount !== undefined) {
        this.setState({ is_checkOut_call: true })
        try {
          var ciphertext = encrypt(reviewData.review);
          let reviewResp = await post("ngp/review", { review: ciphertext });
          let data = decrypt(reviewResp.data.data);

          if (reviewResp.status == 200) {
            // console.log("data: ", data)
            this.setState({ is_checkOut_call: false })
          }

          if (reviewResp.status == 422) {
            toast.error(`${reviewResp.message}`, {
              autoClose: 4000,
            });
          } else {
            const ReactPixel = require("react-facebook-pixel");
            ReactPixel.default.init("1430251647147026");
            ReactPixel.default.init("1326840351009817");
            ReactPixel.default.track("AddToCart");
            var isReview = true;
            var reviewApiData = data;
          }
        } catch (e) {
          console.log("error", e);
        }
      } else {
        var reviewApiData = {};
        this.setState({ alert_for_minimum_amount: true })
      }
    } else if (amount >= this.state.minAmount && amount <= this.state.maxAmount && amount !== undefined) {
      this.setState({ is_checkOut_call: true })
      try {
        var ciphertext = encrypt(reviewData.review);
        let reviewResp = await post("ngp/review", { review: ciphertext });
        let data = decrypt(reviewResp.data.data);

        if (reviewResp.status == 200) {
          this.setState({ is_checkOut_call: false })
        }

        if (reviewResp.status == 422) {
          toast.error(`${reviewResp.message}`, {
            autoClose: 4000,
          });
        } else {
          const ReactPixel = require("react-facebook-pixel");
          ReactPixel.default.init("1430251647147026");
          ReactPixel.default.init("1326840351009817");
          ReactPixel.default.track("AddToCart");
          var isReview = true;
          var reviewApiData = data;
        }
      } catch (e) {
        console.log("error", e);
      }
    } else {
      // toast.error("Please Select Brand", {
      //   autoClose: 4000,
      // });
      this.setState({ alert_for_maximum_amount: true })
    }
    // call login box if user not login
    //this.changeOnlyLogin(true);
    this.setState({
      isReview,
      reviewData: reviewApiData,
      isCheckoutLoader: false,
    });
  };

  changeOnlyLogin = (value) => {
    this.setState({
      onlyLogin: value,
    });
  };

  handleShowPromo = () => {
    this.setState({ showPromocode: !this.state.showPromocode });
  };

  handleBalance = () => {
    var balance =
      this.state.brandData.wallet_cashback == false
        ? Number(this.state.userDetail.wallet || 0)
        : this.state.totalBalance.toString();
    this.setState({
      ngpDiscount: !this.state.ngpDiscount,
      walletBalance: !this.state.walletBalance,
      walletBalanceValue: balance,
    });
  };


  handleGoPointBalance = (e) => {

    // console.log("go Points Bal: ", e)
    var balance = this.state.GoPointDiscount
    balance = this.state.reviewData?.GoPointDiscount
    this.setState({
      pay_by_goPointBalance: e,
      GoPointDiscount: balance
    });
  }


  handleTotalBalance = (e) => {
    var balance =
      this.state.brandData.wallet_cashback == false
        ? Number(this.state.userDetail.wallet || 0)
        : Number(this.state.totalBalance.toString());
    if (e.target.value > balance) {
      this.setState({ walletBalanceValue: balance });
      return;
    }
    this.setState({ walletBalanceValue: e.target.value });
  };

  setPromocode = (promocode) => {
    this.setState({
      promocode: promocode.code,
      promoId: promocode.id,
    });
  };

  onChangePromocode = (e) => {
    this.setState({ promocode: e.target.value });
  };

  applyPromoCode = async () => {
    if (this.state.amount > 0) {
      if (this.state.promocode != "") {
        const promoDetail = {
          promo_code: this.state.promocode,
          amount: this.state.amount,
        };
        try {
          const { data } = await post("promo_codes/apply_code", promoDetail);
          if (data.status == "200" && data.data && data.data.data) {
            let promoData = data.data.data;
            this.setState({
              promoCodeId: promoData.promo_code_id,
              promoApplied: promoData.success,
              promoDiscount: promoData.discount,
            });
          }
          if (data.status == "422" && data.errors) {
            toast.error(`${data.errors}`, { autoClose: 2000 });
          }
        } catch (e) {
          console.log("error", e);
        }
      } else {
        toast.error("Please Enter Promo Code", { autoClose: 2000 });
      }
    } else {
      toast.error("Please Select Coupons", { autoClose: 2000 });
    }
  };

  returnPromoAppied = () => {
    return (
      <>
        <span className="promo-apply-msg">
          <i className="fa fa-check"></i>Promo Code Applied
        </span>
      </>
    );
  };

  substractDiscountFromTotal = () => {
    let totalAmout = this.state.coupons.reduce(
      (acc, coupon) => acc + coupon.total_amount,
      0
    );
    return this.state.promoApplied && this.state.promoDiscount
      ? totalAmout - this.state.promoDiscount
      : 0;
  };

  renderGrandTotal = () => {
    let grandTotal = this.state.coupons.reduce(
      (acc, coupon) => acc + coupon.total_amount,
      0
    );
    let ngpDiscount = Number(this.state.userDetail.ngp_gift_discount || 0);
    if (grandTotal > ngpDiscount) {
      grandTotal = grandTotal - ngpDiscount;
    } else {
      grandTotal = 0;
    }
    if (grandTotal > 0 && this.state.promoDiscount) {
      grandTotal = grandTotal - this.state.promoDiscount;
    }
    return grandTotal || 0;
  };

  handlePromo = () => {
    this.setState({ showPromo: true });
  };

  handleDiscount = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
    if (this.state.ngpDiscount === true) {
      this.setState({ walletBalance: false });
    } else if (this.state.walletBalance === true) {
      this.setState({ ngpDiscount: false });
    }
  };

  handleSearch = async (e) => {
    const { name, value } = e.target;

    if (value.length > 3) {
      const searchData = { q: value, is_redeem: false };
      try {
        const { data } = await post("ngp/pay_brand_search", searchData);
      } catch (e) {
        console.log("error", e);
      }
    }

    this.setState({ [name]: value });
  };

  trackCheckout = () => {
    const ReactPixel = require("react-facebook-pixel");
    ReactPixel.default.init("1430251647147026");
    ReactPixel.default.init("1326840351009817");
    ReactPixel.default.track("InitiateCheckout");
    ReactPixel.default.track("AddPaymentInfo");
  };

  fireGTagEvent = (value, transaction_id) => {
    ReactGA.event({
      category: "conversion",
      action: "purchase_conversion_" + transaction_id,
      value: value,
      currency: "INR",
      transaction_id: transaction_id,
    });
  };

  pay = async (walletType, callWallet) => {
    console.log("Window open or Not: ", callWallet)
    this.trackCheckout();
    if (this.isCurrentUser()) {
      this.setState({ isPayLoad: true });
      // console.log("Amount: ", this.state.amount)
      const reviewData = {
        review: {
          amount: this.state.amount,
          brand_id: Number(this.state.brandId),
          //is_gift_amount: this.state.ngpDiscount,
          //gift_amount: Number(this.state.ngpDiscountValue),
          is_wallet_amount: this.state.walletBalance,
          wallet_amount: Number(this.state.walletBalanceValue),
          promo_code_id: this.state.promoCodeId,
          go_points: this.userDetail.go_points,
          pay_by_gopoints: this.state.pay_by_goPointBalance,
          GoPointDiscount: this.state.GoPointDiscount,

        },
      };
      this.setState({ isProcess: true, isCheckoutLoader: true });
      console.log("reviewData.review: ", reviewData.review)
      try {
        const { data } = await post("orders", {
          review: encrypt(reviewData.review),
        });
        this.setState({ isPayLoad: false });
        if (data && data.status === 200) {
          var decryptedData = decrypt(data.data);

          if (callWallet) {
            let transaction_id = `gainon_reward_` + new Date().getTime();
            let encryptGiftData = encrypt({
              paymentId: transaction_id,
              order_id: decryptedData.order_id,
            });
            const { payment } = await post("razorpay/payment", {
              payment: encryptGiftData,
            });
            // console.log(payment)
            this.trackPurchase(decryptedData);
            this.props.history.push(`/orders/${decryptedData.order_id}`);
          } else if (!callWallet) {
            // if (walletType === "paytm") {
            //   window.location.href = `${PAYMENT_REDIRECT_URL}/${data.data.order_id}`;
            // } else if (walletType === "razorpay") {
            this.paymentHandler(
              decryptedData.order_id,
              decryptedData.total_amount,
              decryptedData
            );
            this.setState({ isProcess: false });
            //}
          }
        } else if (data && data.status === 422) {
          alert(data.errors);
          this.setState({ isProcess: false });
        }
      } catch (e) {
        console.log("error", e);
      }
    } else {
      this.changeOnlyLogin(true);
    }
  };

  back = () => {
    this.setState({ isReview: false });
  };

  selectBrand = async (brandId, brandData, brandName, minAmount, maxAmount) => {
    this.setState({ brandId, brandData, brandName, minAmount, maxAmount });
  };

  handleDiscountChange = (e) => {
    let { name, value } = e.target;
    let ngpDiscount = this.state.userDetail
      ? parseInt(this.state.userDetail.ngp_gift_discount)
      : 0;
    let wallet = this.state.userDetail
      ? parseInt(this.state.userDetail.wallet)
      : 0;
    if (name === "ngpDiscountValue" && value > ngpDiscount) {
      value = ngpDiscount;
    } else if (name === "walletBalanceValue" && value > wallet) {
      value = wallet;
    }

    this.setState({ [name]: value });
  };

  handleWallet = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  setTotalAmount = (value) => {
    this.setState({
      totalBalance: value,
      walletBalanceValue: value,
    });
  };

  getVoucherIcon = (reviewData) => {
    // console.log("Review data: " + JSON.stringify(reviewData))
    let newArray = [];
    let newObject = {};
    for (let i in reviewData) {
      let objTitle = reviewData[i]["amount"];
      reviewData[i]["quantity"] =
        newObject[objTitle] && newObject[objTitle].quantity
          ? newObject[objTitle].quantity + 1
          : 1;
      reviewData[i]["totalCashback"] =
        newObject[objTitle] &&
          newObject[objTitle].hasOwnProperty("totalCashback")
          ? newObject[objTitle]["totalCashback"] + reviewData[i]["cash_back"]
          : reviewData[i]["cash_back"];
      newObject[objTitle] = reviewData[i];

    }
    for (let i in newObject) {
      newObject[i]['totalCashback'] = Math.floor(newObject[i]['totalCashback'])
      newArray.push(newObject[i]);
    }

    return newArray.map((reviewItem) => {
      // {console.log("Condition walletBalance is " , !this.state.walletBalance  || !this.state.pay_by_goPointBalance)}
      { console.log("reviewItem.totalCashback", reviewItem.totalCashback) }

      return (
        <>
          <div className="voucher-wrap d-flex align-items-center">
            <div className="flex-grow-1">
              <VoucherIcon
                amount={reviewItem.amount}
                cashBack={
                  reviewItem.totalCashback && ((this.state.walletBalanceValue == 0 || !this.state.walletBalance) && !this.state.pay_by_goPointBalance)
                    ? reviewItem.totalCashback
                    : 0
                }
                data="1000"
                x="0"
                y="0"
              />
            </div>
            <i className="icon-close m-2"></i>
            <span className="number ml-2">{reviewItem.quantity}</span>
          </div>
        </>
      );
    });
  };

  render() {
    const {
      brandName,
      amount,
      isReview,
      reviewData,
      brandId,
      walletBalance,
      ngpDiscount,
      ngpDiscountValue,
      walletBalanceValue,
      brandData,
      isCheckoutLoader,
      minAmount,
      maxAmount,
      totalBalance,
      pay_by_goPointBalance,
      GoPointDiscount,
    } = this.state;
    // New UI
    return (
      <>
        {this.state.isLoading ? <Loader /> : null}
        <Header
          logout={this.logout}
          isCurrentUser={this.isCurrentUser()}
          onlyLogin={this.state.onlyLogin}
          isLoggedIn={this.state.isLoggedIn}
          changeOnlyLogin={this.changeOnlyLogin}
          setTotalAmount={this.setTotalAmount}
          userDetail={this.userDetail}
        />
        <section className="page page-brand">
          <div className="container">
            <Row>
              <Col lg={5} md={12}>
                <Card className="card-brand">
                  <Card.Body>
                    <div className="media brand-title align-items-center justify-content-center">
                      <div className="brand-img-box mr-3">
                        {brandData.image && (
                          <img src={brandData.image} alt="brand logo" />
                        )}
                      </div>
                      <div className="media-body">
                        <h5 className="mt-1">{brandName}</h5>
                      </div>
                    </div>
                    <Form>
                      <Form.Group
                        as={Row}
                        controlId="formLabel"
                        className="coupon-form-input"
                      >
                        <Form.Label column className="d-flex pt-3 text-nowrap">
                          Enter your purchase amount
                        </Form.Label>
                        <Col>
                          <InputGroup className="ml-auto">
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                id="basic-addon1"
                                className="rupee-icon"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 14 14"
                                >
                                  <g transform="translate(-1173 -24)">
                                    <g transform="translate(1147 12)">
                                      <g transform="translate(0 9)">
                                        <g transform="translate(26 1)">
                                          <g transform="translate(0 2)">
                                            <rect
                                              width="14"
                                              height="14"
                                              fill="none"
                                            />
                                            <path
                                              d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                              fill="#333"
                                            />
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              name="amount"
                              onChange={this.handleChange}
                              onKeyPressCapture={this.handleChange}
                              type="text"
                              autoComplete="off"
                              onFocus={() =>
                                this.setState({
                                  alert_for_minimum_amount: false,
                                  alert_for_maximum_amount: false
                                })
                              }
                              value={this.state.amount}
                              placeholder="0"
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              className="bg-primary border-0"
                              style={{ cursor: "pointer" }}
                              onClick={this.check_for_review_API}
                            >
                              <i className="home-page-icon icon-arrow-right text-white" />
                            </InputGroup.Text>
                          </InputGroup>

                          {this.state.alert_for_minimum_amount ? (
                            <div className="text-left">
                              <Form.Text className="text-primary">
                                {!this.state.brandData.coupons[0]
                                  ? `This brand is currently unavailable`
                                  : `Please enter amount at least ₹ ${this.state.minAmount} or above`}
                              </Form.Text>
                            </div>
                          ) : this.state.alert_for_maximum_amount ? (
                            <div className="text-left">
                              <Form.Text className="text-primary">
                                {!this.state.brandData.coupons[0]
                                  ? `This brand is currently unavailable`
                                  : `Please enter amount between ₹ ${this.state.minAmount} - ${this.state.maxAmount} this range`}
                              </Form.Text>
                            </div>
                          ) : (
                            <Form.Text className="text-muted text-right">
                              Min: ₹ {this.state.minAmount}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>
                    </Form>
                    {/* show when brand max value coupon present in  reviewData.coupons */}
                    {brandData &&
                      brandData.id == 17 &&
                      !reviewData.alert_message && (
                        <Alert className="brand-alert">
                          <div className="media">
                            <img
                              src={brandalert}
                              className="mr-2"
                              alt="brand alert icon"
                            />
                            <div className="media-body">
                              <p>
                                Flipkart cashback can be used against your next
                                payment(s) at any other brand, or it can be used
                                on other Redeem brands (e.g. Paytm).
                              </p>
                            </div>
                          </div>
                        </Alert>
                      )}
                    {reviewData &&
                      reviewData.alert_message ==
                      "One Coupon With Outstanding" && (
                        <Alert className="brand-alert">
                          <div className="media">
                            <img
                              src={brandalert}
                              className="mr-2"
                              alt="brand alert icon"
                            />
                            <div className="media-body">
                              <p>
                                We’ve given you the closest possible payment
                                voucher for this brand, any outstanding balance
                                will need to be paid directly at their
                                online/offline store OR please try entering
                                round figures e.g. Rs. 250, 300 etc.
                              </p>
                            </div>
                          </div>
                        </Alert>
                      )}
                    {/* show when multiple voucher and pay at brand value present */}
                    {reviewData &&
                      reviewData.alert_message ==
                      "Multi Coupon No Outstanding" && (
                        <Alert className="brand-alert">
                          <div className="media">
                            <img
                              src={brandalert}
                              className="mr-2"
                              alt="brand alert icon"
                            />
                            <div className="media-body">
                              <p>
                                This brand doesn’t allow the entire payment in
                                one voucher, any outstanding balance will need
                                to be paid directly at their online/offline
                                store OR please try entering round figures e.g.
                                Rs. 250, 300 etc.
                              </p>
                            </div>
                          </div>
                        </Alert>
                      )}
                    {reviewData &&
                      reviewData.alert_message ==
                      "Multi Coupon With Outstanding" && (
                        <Alert className="brand-alert">
                          <div className="media">
                            <img
                              src={brandalert}
                              className="mr-2"
                              alt="brand alert icon"
                            />
                            <div className="media-body">
                              <p>
                                We’ve given you the closest possible payment
                                vouchers for this brand, any outstanding balance
                                will need to be paid directly at their
                                online/offline store OR please try entering
                                round figures e.g. Rs. 250, 300 etc.
                              </p>
                            </div>
                          </div>
                        </Alert>
                      )}
                    {console.log('reviewdata is : ', reviewData)}
                    {reviewData &&
                      reviewData.coupons &&
                      reviewData.coupons.length > 0 ? (
                      this.getVoucherIcon(reviewData.coupons)
                    ) : this.state.is_checkOut_call ? (
                      <div
                        style={{
                          width: "100%",
                          height: "150px",
                          textAlign: "center",
                        }}
                      >
                        <Spinner
                          animation="border"
                          style={{ marginTop: "50px" }}
                        />
                      </div>
                    ) : reviewData && reviewData.pay_amount ?
                      (
                        <div className="voucher-wrap d-flex align-items-center">
                          <span className="flex-grow-1">
                            <VoucherIcon
                              amount={0}
                              cashBack={0}
                              data="1000"
                              x="0"
                              y="0"
                            />
                          </span>
                          <i className="icon-close m-2"></i>
                          <span className="number ml-2">0</span>
                        </div>
                      ) : ''}
                    {this.state.is_checkOut_call ? null : reviewData && reviewData.pay_amount ? (
                      <div className="outstanding-amount">
                        <h6 className="mr-1">
                          Outstanding amount - pay directly to brand:
                        </h6>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 14 14"
                          >
                            <g transform="translate(-1173 -24)">
                              <g transform="translate(1147 12)">
                                <g transform="translate(0 9)">
                                  <g transform="translate(26 1)">
                                    <g transform="translate(0 2)">
                                      <rect
                                        width="14"
                                        height="14"
                                        fill="none"
                                      />
                                      <path
                                        d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                        fill="#333"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                          {reviewData && reviewData.pay_at_store
                            ? reviewData.pay_at_store
                            : 0}
                        </span>
                      </div>
                    ) : ''}
                    <hr />
                    {this.isCurrentUser() &&
                      (this.state.brandData.wallet_cashback == true ||
                        (this.state.brandData.wallet_cashback == false &&
                          Number(this.state.userDetail.wallet || 0) >=
                          250)) && (
                        <>

                          <div className="balance-details d-flex justify-content-between">
                            <p className="balance-text">
                              {" "}
                              Use available Go Points{" "}
                              <span className="mr-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                >
                                  <g transform="translate(-1173 -24)">
                                    <g transform="translate(1147 12)">
                                      <g transform="translate(0 9)">
                                        <g transform="translate(26 1)">
                                          <g transform="translate(0 2)">
                                            <rect
                                              width="14"
                                              height="14"
                                              fill="none"
                                            />
                                            <path
                                              d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                              fill="#333"
                                            />
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>

                                {Number(this.state.userDetail.go_points)}

                                {/* {console.log(this.state.userDetail)} */}
                              </span>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Amount earned as GO Points can be applied as a discount on your next payment up to the Loyalty Cashback that is being offered on the payment
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={tooltipicon}
                                  alt="Tooltip icon"
                                  className="tooltip-img"
                                ></img>
                              </OverlayTrigger>
                            </p>
                            {/* {console.log("reviewdata", reviewData)} */}
                            {reviewData != undefined && (reviewData.GoPointDiscount > 0) && this.state.pay_by_goPointBalance &&
                              (
                                <span className="balance-text d-flex justify-content-center align-item-center text-success">
                                  <div className="d-flex align-items-baseline">
                                    <i className="fa fa-check-circle text-success"></i>
                                    <p className="balance-text text-success mx-1">saved </p>
                                  </div>
                                  <div className="d-flex align-items-baseline mx-1">

                                    <i className="fa fa-inr "></i>
                                    {

                                      this.state.pay_by_goPointBalance && this.userDetail.go_points != 0 ? `${reviewData.GoPointDiscount}` : "Data"
                                    }
                                  </div>
                                </span>
                              )}
                            <Form>
                              <Form.Check
                                type="switch"
                                onChange={(e) =>
                                  this.handleGoPointBalance(e.target.checked)
                                }
                                id="custom-switch-Go-Points"
                                label=""
                              />
                            </Form>
                          </div>

                          <div className="balance-details d-flex align-items-center justify-content-between mb-3">
                            <p className="balance-text mb-0">
                              {" "}
                              Use available balance{" "}
                              <span className="mr-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                >
                                  <g transform="translate(-1173 -24)">
                                    <g transform="translate(1147 12)">
                                      <g transform="translate(0 9)">
                                        <g transform="translate(26 1)">
                                          <g transform="translate(0 2)">
                                            <rect
                                              width="14"
                                              height="14"
                                              fill="none"
                                            />
                                            <path
                                              d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                              fill="#333"
                                            />
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>

                                {this.state.brandData.wallet_cashback == false &&
                                  Number(this.state.userDetail.wallet || 0)}
                                {this.state.brandData.wallet_cashback == true &&
                                  totalBalance}
                              </span>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Amount earned as Loyalty Cashback on payments, you
                                    can redeem this amount as a discount on your next payment
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={tooltipicon}
                                  alt="Tooltip icon"
                                  className="tooltip-img"
                                ></img>
                              </OverlayTrigger>
                            </p>
                            <Form>
                              <Form.Check
                                type="switch"
                                onChange={() => this.handleBalance()}
                                id="custom-switch"
                                label=""
                              />
                            </Form>
                          </div>
                          {this.state.ngpDiscount && (
                            // <div className="d-sm-block">
                            <InputGroup
                              className="balance-value  "
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text
                                  id="basic-addon1"
                                  className="rupee-icon"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 14 14"
                                  >
                                    <g transform="translate(-1173 -24)">
                                      <g transform="translate(1147 12)">
                                        <g transform="translate(0 9)">
                                          <g transform="translate(26 1)">
                                            <g transform="translate(0 2)">
                                              <rect
                                                width="14"
                                                height="14"
                                                fill="none"
                                              />
                                              <path
                                                d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                                fill="#333"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                type="text"
                                // placeholder="Enter amount"
                                // defaultValue={'100'}
                                value={walletBalanceValue}
                                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                onInput={(e) => {
                                  const re = /[^0-9]/g
                                  const zerore = /^0/
                                  if (re.test(e.target.value) || zerore.test(e.target.value)) {
                                    return e.target.value = null;
                                  }
                                }}
                                onChange={(e) => this.handleTotalBalance(e)}
                              />
                            </InputGroup>
                            // </div>
                          )}
                        </>
                      )}

                    {/* 
                    <div className="balance-details d-flex justify-content-between">
                      <p className="balance-text">
                        {" "}
                        Used Go Points{" "}
                        {reviewData.GoPointDiscount && (<span className="mr-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g transform="translate(-1173 -24)">
                              <g transform="translate(1147 12)">
                                <g transform="translate(0 9)">
                                  <g transform="translate(26 1)">
                                    <g transform="translate(0 2)">
                                      <rect
                                        width="14"
                                        height="14"
                                        fill="none"
                                      />
                                      <path
                                        d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                        fill="#333"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>

                          {this.state.pay_by_goPointBalance
                              ? `-${reviewData.GoPointDiscount}`
                              : ""}

                         
                        </span>
                      )}

                      </p>

                    </div> */}
                    {/* {this.state.ngpDiscount && (
                      <div className="d-sm-block">
                        <InputGroup className="balance-value">
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              id="basic-addon1"
                              className="rupee-icon"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 14 14"
                              >
                                <g transform="translate(-1173 -24)">
                                  <g transform="translate(1147 12)">
                                    <g transform="translate(0 9)">
                                      <g transform="translate(26 1)">
                                        <g transform="translate(0 2)">
                                          <rect
                                            width="14"
                                            height="14"
                                            fill="none"
                                          />
                                          <path
                                            d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                            fill="#333"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="text"
                            // placeholder="Enter amount"
                            // defaultValue={'100'}
                            value={walletBalanceValue}
                            onChange={(e) => this.handleTotalBalance(e)}
                          />
                        </InputGroup>
                      </div>
                    )} */}
                    <div className="pay-wrap d-flex align-items-center flex-column">
                      {reviewData &&
                        reviewData.coupons &&
                        reviewData.coupons.length > 0 ? (
                        <Button
                          variant="primary"
                          onClick={() => {
                            this.pay(
                              "razorpay",
                              ngpDiscount && walletBalance && pay_by_goPointBalance &&
                                (reviewData.total_coupons_amount - walletBalanceValue - GoPointDiscount) <= 0 ? true
                                :
                                ngpDiscount && walletBalance
                                  ? reviewData.total_coupons_amount -
                                    walletBalanceValue >
                                    0
                                    ? false
                                    : true
                                  : false
                            );
                            // {console.log("Calculatio is : ", reviewData.total_coupons_amount - walletBalanceValue - GoPointDiscount);}
                          }}
                          disabled={this.state.isProcess}
                        >
                          {/* {this.state.isProcess ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            `Continue to Pay ₹ ${
                              ngpDiscount && walletBalance && pay_by_goPointBalance
                                ? reviewData.total_coupons_amount - walletBalanceValue > 0 || this.userDetail.go_points > 0
                                  ? reviewData.total_coupons_amount - walletBalanceValue - GoPointDiscount
                                  : 0
                                : pay_by_goPointBalance ? reviewData.total_coupons_amount - reviewData.GoPointDiscount
                                : ngpDiscount && pay_by_goPointBalance ? reviewData.total_coupons_amount - walletBalanceValue - GoPointDiscount
                                : ngpDiscount && walletBalance ? reviewData.total_coupons_amount - walletBalanceValue > 0
                                  ? reviewData.total_coupons_amount - walletBalanceValue
                                  : 0
                                : reviewData.total_coupons_amount
                            }`
                            )} */}

                          {/* {console.log(reviewData.total_coupons_amount - walletBalanceValue - GoPointDiscount)} */}
                          {this.state.isProcess ? (
                            <Spinner animation="border" size="sm" />
                          ) :
                            (`Continue to Pay ₹
                          ${!pay_by_goPointBalance ?
                                (ngpDiscount && walletBalance
                                  ? reviewData.total_coupons_amount -
                                    walletBalanceValue >
                                    0
                                    ? reviewData.total_coupons_amount -
                                    walletBalanceValue
                                    : 0
                                  : reviewData.total_coupons_amount
                                )
                                :
                                ngpDiscount && walletBalance && pay_by_goPointBalance ?
                                  (
                                    reviewData.total_coupons_amount - GoPointDiscount - walletBalanceValue > 0
                                      ?
                                      reviewData.total_coupons_amount - GoPointDiscount - walletBalanceValue
                                      :
                                      0
                                  )
                                  :
                                  pay_by_goPointBalance ?
                                    (
                                      reviewData.total_coupons_amount - GoPointDiscount > 0
                                        ?
                                        reviewData.total_coupons_amount - GoPointDiscount
                                        :
                                        0
                                    )
                                    :
                                    reviewData.total_coupons_amount
                              }`)}
                        </Button>
                      ) : (
                        <Button variant="primary" disabled>
                          Continue to Pay
                        </Button>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={7} md={12} className="brand-gotabs">
                <BrandStep brandData={this.state.brandData} isRedeem={false} />
              </Col>
            </Row>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default Brand;
